*{
  box-sizing: border-box;
}

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  padding: 4rem 2rem 0;
}

.left{
  text-align: left;
  font-size: 1.4rem;
}

.red{
  color: red;
}

.App > img{
  max-width: 200px;
  margin-bottom: 10rem
}

.white{
  color: #fff;
}

.paypal{
  max-width: 400px;
  margin: auto;
}

.App form{
  width: 100%;
  margin: auto;
}

.App form input{
  width: 100%;
  padding: 1rem;
  margin: 2rem 0;
  font-size: 1.2rem;
}

.button{
  background: #000;
  color: #fff;
  padding: 1rem;
  border: none;
  width: 100%;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 4px;
}

@media screen and (max-width: 300px){
  .App .paypal{
    max-width: 400px;
    margin: auto;
  }
}